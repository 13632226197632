<template>
  <div class="ui-data-list">
    <template v-if="isLoading && !isScrolling">
      <div class="ui-data-list__item" v-for="n in skeletonLoop" :key="`item-skeleton-${n}`">
        <slot name="skeleton"></slot>
      </div>
    </template>
    <template v-else>
      <div class="ui-data-list__item" v-for="(item, idx) in items" :key="`item-${idx}`" @click="onClick(item)">
        <div class="ui-data-list__item__triangle"></div>
        <slot name="item" :item="item" :index="idx"></slot>
      </div>
    </template>
    <ui-list-loader :is-loading="isScrolling && !noMoreData" />
  </div>
</template>

<script>
import UiListLoader from '@/components/UI/ListLoader.vue'

export default {
  name: 'UiDataList',
  components: {
    UiListLoader,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMoreData: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeletonLoop: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  methods: {
    onClick(item) {
      this.$emit('click', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-data-list {
  &__item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    transition: color $transition-duration-default $transition-effect-default;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--bg-color);
    padding: $gutter-mobile;
    min-height: 64px;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet;
    }

    &:last-child {
      border-bottom: 0;
    }

    &__triangle {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity $transition-duration-default $transition-effect-default;
      opacity: 0;
      border-top: 30px solid $blue-dodger;
      border-right: 30px solid transparent;
      width: 0;
      height: 0;
    }

    &:hover {
      cursor: pointer;
      color: $blue-dodger;

      .ui-data-list__item__triangle {
        opacity: 1;
      }
    }
  }
}
</style>
