<template>
  <component
    :is="'button'"
    class="ui-filter"
    :aria-label="label"
    :aria-labelledby="label"
    :disabled="disabled"
    @click="onClick"
    v-bind:style="{ backgroundColor: wrappedColors.background }"
  >
    <span class="ui-filter__label" v-bind:style="{ color: wrappedColors.labelText }">
      {{ label }}
    </span>
    <ui-bubble class="ui-filter__bubble" :count="bubbleCount" :variant="wrappedColors.bubbleVariant" />
  </component>
</template>

<script>
import UiBubble from '@/components/UI/Bubble.vue'

const defaultColors = {
  background: '#f7f8fb',
  backgroundSelected: '#b674d3',
  labelText: 'black',
  labelTextSelected: 'white',
  bubbleVariant: 'dark-violet',
  bubbleVariantSelected: 'dark-violet',
}

export default {
  name: 'UiFilter',
  components: { UiBubble },
  props: {
    label: {
      type: String,
      required: true,
    },
    bubbleCount: {
      type: Number,
      required: false,
      default: 0,
    },
    initialSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    colors: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isSelected: this.initialSelected,
    }
  },
  computed: {
    wrappedColors() {
      const colors = {
        ...defaultColors,
        ...this.colors,
      }
      return {
        background: colors[`background${this.isSelected ? 'Selected' : ''}`],
        labelText: colors[`labelText${this.isSelected ? 'Selected' : ''}`],
        bubbleVariant: colors[`bubbleVariant${this.isSelected ? 'Selected' : ''}`],
      }
    },
  },
  methods: {
    onClick($event) {
      /**
       * Emitted when the button is clicked.
       * @event click
       * @type {Event}
       */
      this.isSelected = !this.isSelected
      this.$emit('onClick', $event)
    },
  },
}
</script>

<style lang="scss">
.ui-filter {
  @include button-primary;

  justify-content: space-between;
  margin: 8px 0;
  background: $grey-very-light;
  padding: 0 8px;
  min-width: 62px;
  min-height: 24px;

  &__label {
    font-size: 12px;
    font-weight: lighter;
  }
  &__bubble {
    margin-left: 8px;
    background: #b674d3;
  }
}
</style>
