<template>
  <div class="gmb-qa-edit" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="gmb-qa-edit__header"
      :title="$t('gmbQa.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
      ref="fixedElement"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true" :noPadding="true">
          <template v-slot:body>
            <div class="gmb-qa-edit__content">
              <questions-panel
                v-if="!isMobileOrTablet || (isMobileOrTablet && currentQuestionsAnswersDetail == null)"
                :isLoading="isLoading"
                :isScrolling="isScrolling"
                :locationName="currentLocation.locationName"
                :currentQuestions="currentQuestionsAnswers"
                :pagination="currentQuestionsAnswersPagination"
                :accountId="currentAccountName"
                :locationId="currentLocationName"
                :ROLE_MERCHANT="ROLE_MERCHANT"
                @selectQuestionInDataList="selectQuestionInDataList"
                @onClickQuestionsPaginationButton="onClickQuestionsPaginationButton"
              />
              <responses-panel
                v-if="!isMobileOrTablet || (isMobileOrTablet && currentQuestionsAnswersDetail != null)"
                :isLoading="isLoadingDetail"
                :isScrolling="isScrolling"
                :hasMoreAnswersToLoad="false"
                :selectedQuestion="currentQuestionsAnswersDetail"
                :respondAccount="{
                  clientLogo: currentClient.logo,
                  locationName: currentLocation.locationName,
                  role: ROLE_MERCHANT,
                }"
                :ROLE_MERCHANT="ROLE_MERCHANT"
              />
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import QuestionsPanel from '@/components/Qa/QuestionsPanel.vue'
import ResponsesPanel from '@/components/Qa/ResponsesPanel.vue'
import { onScroll } from '@/mixins/scroll.mixin'

export default {
  name: 'GmbQaEdit',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonIcon,
    QuestionsPanel,
    ResponsesPanel,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isLoadingDetail: false,
      isScrolling: false,
      isMobileOrTablet: window.innerWidth < 992,
      ROLE_MERCHANT: 'MERCHANT',
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  async created() {
    this.isLoading = true

    await Promise.all([
      this.loadLocation({
        locationName: this.currentLocationName,
        currentAccountName: this.currentAccountName,
      }),
      this.getAccounts({}),
    ])

    if (!this.$route.params.name) {
      this.$router.replace({
        ...this.$route,
        params: { ...this.$route.params, name: this.accounts[0].name },
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await this.loadGmbQa()
        }
      },
    },
  },
  computed: {
    ...mapState({
      accounts: state => state.gmb.accounts,
      currentLocation: state => state.gmb.currentLocation,
      currentQuestionsAnswers: state => state.qa.currentQuestionsAnswers?.data || [],
      currentQuestionsAnswersPagination: state =>
        state.qa.currentQuestionsAnswers?.pagination || { page: 1, nbPages: 1 },
      currentQuestionsAnswersDetail: state => state.qa.currentQuestionsAnswersDetail,
      currentClient: state => state.user.currentClient,
    }),
    currentLocationName() {
      return this.$route.params.name
    },
    currentAccountName() {
      return this.$route.params.name.split('/locations')[0]
    },
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmbQa.breadcrumb'),
          route: {
            name: 'GmbQa',
            params: {
              name: this.currentAccountName,
            },
          },
        },
        {
          label: this.$t('gmbQaEdit.breadcrumb'),
          route: {
            name: 'GmbQaEdit',
            params: {
              name: this.currentAccountName,
            },
          },
        },
      ]
    },
    hasMoreQuestionToLoad() {
      const { nbPages, page } = this.currentQuestionsAnswersPagination
      return nbPages > page
    },
  },
  methods: {
    ...mapActions({
      loadQuestionsAnswers: 'qa/loadQuestionsAnswers',
      getQuestionsAnswersDetail: 'qa/getQuestionsAnswersDetail',
      getAccounts: 'gmb/getAccounts',
      loadLocation: 'gmb/loadLocation',
    }),
    onResize() {
      this.isMobileOrTablet = window.innerWidth < 992
    },
    async loadGmbQa() {
      this.isLoading = true
      try {
        await this.loadQuestionsAnswers({ account: this.currentAccountName, location: this.currentLocationName })
      } finally {
        this.isLoading = false
      }
    },
    async loadMoreGmbQa(page) {
      this.isLoading = true
      try {
        await this.loadQuestionsAnswers({
          account: this.currentAccountName,
          location: this.currentLocationName,
          page: page || this.currentQuestionsAnswersPagination.page + 1,
        })
      } finally {
        this.isLoading = false
      }
    },
    async selectQuestionInDataList(data) {
      this.isLoadingDetail = true
      try {
        await this.getQuestionsAnswersDetail({
          account: this.currentAccountName,
          location: data.location,
          id: data.name,
        })
      } finally {
        this.isLoadingDetail = false
      }
    },
    async onClickQuestionsPaginationButton(page = null) {
      await this.loadMoreGmbQa(page)
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-qa-edit {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  /* stylelint-disable selector-pseudo-element-no-unknown */
  ::v-deep.ui-data-list__item {
    padding: 0;
  }
  /* stylelint-enable selector-pseudo-element-no-unknown */

  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    height: 100%;
  }
}
</style>
