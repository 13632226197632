<template>
  <transition name="fade">
    <div class="ui-list-loader" v-if="isLoading">
      <ui-loader />
    </div>
  </transition>
</template>

<script>
import UiLoader from '@/components/UI/Loader.vue'

export default {
  name: 'UiListLoader',
  components: {
    UiLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-list-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $gutter-mobile 0;
}
</style>
