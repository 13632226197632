<template>
  <div class="responses-panel">
    <span v-if="!selectedQuestion && !isLoading" class="responses-panel__no__selection">
      {{ $t('gmbQaEdit.noSelectedQuestion') }}
    </span>
    <skeleton-line v-else-if="isLoading" class="responses-panel__noSelectionSkeleton" height="23px" width="100%" />
    <div v-if="!!selectedQuestion && !isLoading" class="responses-panel__responses">
      <ui-data-list
        class="responses-panel__responses__list"
        :items="[selectedQuestion, ...selectedQuestion.topAnswers]"
        :is-loading="isLoading"
        :is-scrolling="isScrolling"
        :no-more-data="!hasMoreAnswersToLoad"
      >
        <template v-slot:item="{ item, index }">
          <question-answer-row
            class="responses-panel__responses__item"
            v-bind:class="{ 'question-row': index === 0 }"
            :item="item"
            :withUpVoteButton="true"
            :ROLE_MERCHANT="ROLE_MERCHANT"
            @onRowClickEdit="onRowOptionsClickEditResponse"
            @onRowClickDelete="onRowOptionsClickDeleteResponse"
          />
        </template>
      </ui-data-list>
      <ui-button
        v-if="!responseRowDisplayed"
        class="responses-panel__responses__button"
        button="primary"
        :label="$t('gmbQaEdit.button.respond')"
        @click="onClickRespondButton"
      />
      <respond-row
        v-if="responseRowDisplayed && respondAccount != null"
        :isLoading="isLoading"
        :name="respondAccount.locationName"
        :picture="respondAccount.clientLogo"
        :type="respondAccount.role"
        @onClickCancel="responseRowDisplayed = false"
        @onClickPublish="publishModalDisplayed = true"
        @onTextareaInputChange="onInputResponseChange"
      />
    </div>
    <confirm-publish-modal
      v-if="publishModalDisplayed"
      @onClickNo="publishModalDisplayed = false"
      @onClickYes="onConfirmResponseModalClickYes"
    />
  </div>
</template>

<script>
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDataList from '@/components/UI/DataList.vue'
import QuestionAnswerRow from '@/components/Qa/QuestionAnswerRow.vue'
import RespondRow from '@/components/Qa/RespondRow.vue'
import ConfirmPublishModal from '@/components/Qa/ConfirmPublishModal.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions } from 'vuex'

export default {
  name: 'ResponsesPanel',
  components: {
    SkeletonLine,
    UiButton,
    UiDataList,
    QuestionAnswerRow,
    RespondRow,
    ConfirmPublishModal,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    hasMoreAnswersToLoad: {
      type: Boolean,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      required: false,
    },
    respondAccount: {
      type: Object,
      required: false,
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
  },
  mixins: [onScroll],
  data: () => ({
    responseRowDisplayed: false,
    publishModalDisplayed: false,
    responseValue: '',
    answerToUpdate: null,
  }),
  methods: {
    ...mapActions({
      updateQuestion: 'qa/updateQuestion',
      createAnswer: 'qa/createAnswer',
      updateAnswer: 'qa/updateAnswer',
      deleteAnswer: 'qa/deleteAnswer',
    }),
    onInputResponseChange(value) {
      this.responseValue = value
    },
    onClickRespondButton() {
      this.answerToUpdate = null
      this.responseRowDisplayed = true
    },
    async onConfirmResponseModalClickYes() {
      if (this.answerToUpdate == null) {
        await this.createAnswer({
          account: this.selectedQuestion.name.split('/locations')[0],
          location: this.selectedQuestion.name.split('/question')[0],
          id: this.selectedQuestion.name,
          text: this.responseValue,
        })
      } else if (this.answerToUpdate.name === this.selectedQuestion.name) {
        await this.updateQuestion({
          account: this.selectedQuestion.name.split('/locations')[0],
          location: this.selectedQuestion.name.split('/question')[0],
          text: this.responseValue,
        })
      } else {
        await this.updateAnswer({
          account: this.selectedQuestion.name.split('/locations')[0],
          location: this.selectedQuestion.name.split('/question')[0],
          id: this.selectedQuestion.name,
          text: this.responseValue,
        })
      }
      this.publishModalDisplayed = false
      this.responseRowDisplayed = false
    },
    async onRowOptionsClickEditResponse(row) {
      this.answerToUpdate = row
      this.responseRowDisplayed = true
    },
    async onRowOptionsClickDeleteResponse(row) {
      await this.deleteAnswer({
        account: this.selectedQuestion.name.split('/locations')[0],
        location: this.selectedQuestion.name.split('/question')[0],
        id: row.name,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.responses-panel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 60%;

  @media (max-width: $screen-md) {
    width: 100%;
  }

  &__no__selection {
    margin: auto;
  }
  &__noSelectionSkeleton {
    margin: auto;
  }

  &__responses {
    margin: 24px;
    overflow-y: auto;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* stylelint-disable selector-pseudo-element-no-unknown */
    ::v-deep .ui-data-list__item {
      margin-right: 0;
      margin-left: 57px;
      border-bottom: 0;

      &:hover {
        background: white;
      }
      &:first-child {
        margin-right: 57px;
        margin-left: 0;
      }
    }
    /* stylelint-enable selector-pseudo-element-no-unknown */

    &__item {
      margin-bottom: 16px;
      border: 1px solid #dcdee5;
      border-radius: 12px;
      background-clip: border-box;
    }
    .question-row {
      border: 0;
      background: $grey-very-light;
    }

    &__button {
      display: flex;
      margin: auto;
      border: 0;
      background: linear-gradient(to left, #1faae6, #4e5cec);
      height: 32px;
      min-height: 32px;
      color: white;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &__responses::-webkit-scrollbar {
    display: none;
  }
}
</style>
