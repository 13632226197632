<template>
  <div class="respond-row">
    <div class="respond-row__top">
      <div class="respond-row__top__header">
        <img class="respond-row__top__header__picture" :src="picture" alt="" />
        <div class="respond-row__top__header__author">
          <span class="respond-row__top__header__author__name">{{ name }}</span>
          <span class="respond-row__top__header__author__type">{{ type }}</span>
        </div>
      </div>
      <ui-textarea
        id="respond-row__top__textarea"
        class="respond-row__top__textarea"
        design="minimalist"
        :rows="2"
        :label="$t('respondRow.textarea.label')"
        @input="onTextareaInputChange"
      />
      <div class="respond-row__top__buttons">
        <ui-button
          class="respond-row__top__buttons__cancel"
          button="primary"
          :label="$t('respondRow.button.cancel').toUpperCase()"
          @click="onClickCancel"
        />
        <ui-button
          class="respond-row__top__buttons__publish"
          button="primary"
          :label="$t('respondRow.button.publish').toUpperCase()"
          :disabled="isPublishDisabled"
          @click="onClickPublish"
        />
      </div>
    </div>
    <div class="respond-row__bottom">
      <span>{{ $t('respondRow.rgpd.label') }}</span>
      <a href="https://support.google.com/contributionpolicy/answer/7412443">{{ $t('respondRow.rgpd.link') }}</a>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiTextarea from '@/components/UI/Textarea.vue'

export default {
  name: 'RespondRow',
  components: {
    UiButton,
    UiTextarea,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isPublishDisabled: true,
  }),
  methods: {
    onTextareaInputChange(value) {
      this.isPublishDisabled = value === ''
      this.$emit('onTextareaInputChange', value)
    },
    onClickCancel(e) {
      this.$emit('onClickCancel', e)
    },
    onClickPublish(e) {
      this.$emit('onClickPublish', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.respond-row {
  width: 100%;

  &__top {
    margin-bottom: 16px;
    border: 1px solid #dcdee5;
    border-radius: 12px;
    background-clip: border-box;
    padding: 20px 24px 20px 24px;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      &__picture {
        margin-bottom: 12px;
        width: 48px;
        height: auto;
      }

      &__author {
        display: flex;
        flex-direction: column;

        &__name {
          margin-left: 12px;
          font-size: 13px;
        }

        &__type {
          margin-left: 12px;
          font-size: 12px;
          font-weight: lighter;
        }
      }
    }

    &__textarea {
      /* stylelint-disable selector-pseudo-element-no-unknown */
      ::v-deep > div > textarea {
        border: 0;
        background: white;

        &::placeholder {
          color: $blue-placeholder;
          font-size: 13px;
          font-weight: 400;
        }

        &:focus {
          background: white;
        }
      }
      /* stylelint-enable selector-pseudo-element-no-unknown */
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;

      > button {
        height: 32px;
        min-height: 32px;
      }

      &__cancel {
        flex-grow: 0.1;
      }

      &__publish {
        flex-grow: 0.4;
        margin-left: 16px;
        border: 0;
        background: linear-gradient(to right, #1faae6, #4e5cec);
        color: white;
      }
    }
  }

  &__bottom {
    margin: auto 24px;
    font-size: 12px;
  }
}
</style>
