<template>
  <div class="question-answer-row">
    <div class="question-answer-row__header">
      <img class="question-answer-row__header__picture" :src="item.author.profilePhotoUrl" alt="" />
      <div class="question-answer-row__header__author">
        <span class="question-answer-row__header__author__name">{{ item.author.displayName }}</span>
        <span class="question-answer-row__header__author__type">{{ item.author.type }}</span>
      </div>
      <span class="question-answer-row__header__date">{{ date(item.createTime) }}</span>
      <ui-button
        v-if="item.author.type === ROLE_MERCHANT"
        class="question-answer-row__header__optionsButton"
        button="secondary"
        icon="more_vert"
        label=""
        :iconOnly="true"
        @click="onClickOptions"
      />
      <transition name="fade">
        <div v-if="displayOptionsPopup" class="question-answer-row__header__options">
          <ui-button
            class="question-answer-row__header__options__edit"
            button="secondary"
            :label="$t('gmbQaEdit.button.edit')"
            @click="onClickEdit"
          />
          <ui-button
            class="question-answer-row__header__options__delete"
            button="secondary"
            :label="$t('gmbQaEdit.button.delete')"
            @click="onClickDelete"
          />
        </div>
      </transition>
    </div>
    <div class="question-answer-row__question">
      <span class="question-answer-row__question__text">{{ item.text }}</span>
      <ui-button
        v-if="withUpVoteButton"
        v-bind:class="{ 'all-ready-up-voted': item.upvoteCount > 0 }"
        class="question-answer-row__question__upvote"
        button="secondary"
        icon="thumbs-up"
        :label="item.upvoteCount > 0 ? `${item.upvoteCount}` : ''"
      />
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'QuestionAnswerRow',
  components: {
    UiButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    withUpVoteButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    displayOptionsPopup: false,
  }),
  methods: {
    onClickOptions(event) {
      event.stopPropagation()
      this.displayOptionsPopup = !this.displayOptionsPopup
    },
    onClickEdit(event) {
      event.stopPropagation()
      this.$emit('onRowClickEdit', this.item)
      this.displayOptionsPopup = false
    },
    onClickDelete(event) {
      event.stopPropagation()
      this.$emit('onRowClickDelete', this.item)
      this.displayOptionsPopup = false
    },
    date(str) {
      return new Date(str).toLocaleDateString(this.$i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.question-answer-row {
  padding: 20px 24px 20px 24px;
  width: 100%;

  &__header {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;

    &__picture {
      margin-bottom: 12px;
      width: 40px;
      height: auto;
    }
    &__author {
      display: flex;
      flex-direction: column;
      &__name {
        margin-left: 12px;
        font-size: 13px;
      }

      &__type {
        margin-left: 12px;
        font-size: 12px;
        font-weight: lighter;
      }
    }
    &__date {
      margin-right: 16px;
      margin-left: auto;
    }
    &__optionsButton {
      margin-right: -8px;
      max-width: 16px;
      max-height: 16px;
    }
    &__options {
      display: flex;
      position: absolute;
      top: -8px;
      right: 16px;
      flex-direction: column;
      flex-wrap: nowrap;
      border: 1px solid $grey-good-table-sep;
      border-radius: 12px;
      background: white;
      min-width: 100px;
    }
  }
  &__question {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &__text {
      margin-left: 16px;
      font-size: 13px;
    }
    &__upvote {
      margin: 0 8px 14px auto;
      padding: 0;
      min-width: 30px;
      min-height: 19px;
      color: #dcdee6;
    }
    .all-ready-up-voted {
      color: black;
    }
  }
}
</style>
