<template>
  <div
    class="ui-input"
    :class="{
      'ui-input--disabled': disabled,
      'ui-input--default': design === 'default',
    }"
  >
    <label
      :for="id"
      class="ui-input__label"
      :class="{
        'ui-input__label--error': error,
        'ui-input__label--full': design === 'full',
      }"
      v-if="design === 'full'"
    >
      {{ decoratedLabel }}
    </label>
    <div class="ui-input__wrapper">
      <input
        :id="id"
        :type="type"
        class="ui-input__wrapper__field"
        :class="{
          'ui-input__wrapper__field--icon-left': icon && !reverse,
          'ui-input__wrapper__field--icon-right': icon && reverse,
          'ui-input__wrapper__field--error': error,
        }"
        :placeholder="design === 'minimalist' || design === 'full' ? decoratedLabel : ' '"
        :value="inputValue"
        @input="onInput($event.target.value)"
        :autocomplete="autocomplete ? 'on' : 'off'"
        :disabled="disabled"
        :readonly="readonly"
        @keyup.enter="onEnter($event.target.value)"
      />
      <span
        class="ui-input__wrapper__icon backoffice-icons"
        :class="{
          'ui-input__wrapper__icon--left': icon && !reverse,
          'ui-input__wrapper__icon--right': icon && reverse,
          'ui-input__wrapper__icon--error': error,
        }"
        v-if="icon"
      >
        {{ icon }}
      </span>
      <label
        :for="id"
        class="ui-input__wrapper__label"
        :class="{
          'ui-input__wrapper__label--icon-left': icon && !reverse,
          'ui-input__wrapper__label--icon-right': icon && reverse,
          'ui-input__wrapper__label--error': error,
          'ui-input__wrapper__label--hidden': design === 'minimalist',
        }"
        v-if="design !== 'full'"
      >
        {{ decoratedLabel }}
      </label>
    </div>
    <transition name="fade">
      <div class="ui-input__helper" :class="{ 'ui-input__helper--error': error }" v-if="helper || error">
        <slot>{{ helper }}</slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { isRTL } from '@/utils/i18n.util'

export default {
  name: 'UiInput',
  model: {
    prop: 'inputValue',
  },
  props: {
    inputValue: {
      type: [String, Number],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    helper: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    design: {
      type: String,
      required: false,
      default: 'default',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onInput(value) {
      /**
       * Emitted when the value of the input changed.
       * @event input
       * @type {Event}
       */
      this.$emit('input', value)
    },
    onEnter(value) {
      /**
       * Emitted when the ENTER key is triggered.
       * @event input
       * @type {Event}
       */
      this.$emit('enter', value)
    },
  },
  computed: {
    decoratedLabel() {
      return this.isRequired ? (isRTL(this.$i18n.locale) ? `* ${this.label}` : `${this.label} *`) : this.label
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-input {
  @include input;

  &__label {
    @include input-label;
  }

  &__wrapper {
    @include input-wrapper;

    &__field {
      @include input-field;
    }

    &__label {
      @include input-label;
    }

    &__icon {
      @include input-icon;
    }
  }

  &__helper {
    @include input-helper;
  }
}
</style>
