<template>
  <div class="questions-panel">
    <div class="questions-panel__top">
      <ui-input
        v-if="!isLoading"
        class="questions-panel__top__input"
        v-model="query"
        id="query"
        :label="$t('gmbQaEdit.searchChain.query')"
        design="minimalist"
        icon="search"
        @input="onInput"
      />
      <skeleton-input v-else margin-top="auto" margin-left="auto" margin-right="auto" width="90%" />
    </div>
    <div class="questions-panel__filters">
      <div class="questions-panel__filters__top">
        <ui-button
          class="questions-panel__filters__top__back"
          button="secondary"
          icon="chevron_left"
          :label="$t('gmbQaEdit.back.label')"
          :iconOnly="true"
          @click="back"
        />
        <ui-title v-if="!isLoading" :title="locationName" />
        <skeleton-line v-else height="23px" />
      </div>
      <div class="questions-panel__filters__bottom">
        <ui-filter
          class="questions-panel__filters__bottom__all"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.all.label')"
          :disabled="isLoading"
          @onClick="onClickFilter"
        />
        <ui-filter
          class="questions-panel__filters__bottom__unreaded"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.unreaded.label')"
          :disabled="isLoading"
          :colors="{
            backgroundSelected: '#00afdf',
            bubbleVariant: 'blue-cerulean',
            bubbleVariantSelected: 'blue-viking',
          }"
          @onClick="onClickFilter"
        />
        <ui-filter
          class="questions-panel__filters__bottom__unreplied"
          :bubbleCount="10"
          :label="$t('gmbQaEdit.filters.unreplied.label')"
          :disabled="isLoading"
          :colors="{
            backgroundSelected: '#ef4036',
            bubbleVariant: 'red',
            bubbleVariantSelected: 'red',
          }"
          @onClick="onClickFilter"
        />
      </div>
    </div>
    <div class="questions-panel__create">
      <span class="questions-panel__create__label">{{ $t('gmbQaEdit.label.createQuestion') }}</span>
      <ui-button
        class="questions-panel__create__button"
        button="secondary"
        icon="add"
        label=""
        :iconOnly="true"
        @click="onClickCreateQuestion"
      />
    </div>
    <ui-data-list
      class="questions-panel__list"
      :items="currentQuestions"
      :is-loading="isLoading"
      :is-scrolling="isScrolling"
      :no-more-data="pagination.page >= pagination.nbPages"
      @click="selectQuestionInDataList"
    >
      <template v-slot:item="{ item }">
        <question-answer-row
          :item="item"
          :ROLE_MERCHANT="ROLE_MERCHANT"
          @onRowClickEdit="onRowOptionsClickEditQuestion"
          @onRowClickDelete="onRowOptionsClickDeleteQuestion"
        />
      </template>
    </ui-data-list>
    <div class="questions-panel__pagination">
      <template v-for="item in pagination.nbPages">
        <ui-button
          v-bind:key="item"
          class="questions-panel__pagination__button"
          v-bind:class="{ 'selected-page-button': item === parseInt(pagination.page, 10) }"
          button="secondary"
          :label="`${item}`"
          :disabled="item === parseInt(pagination.page, 10)"
          @click="onClickPaginationButton(item)"
        />
      </template>
    </div>
    <modal-simple-field
      :title="$t(`gmbQaEdit.label.${editingModal}Question`)"
      :is-updating="isLoading"
      :data="modalAddQuestion.data"
      :label="modalAddQuestion.label"
      :obj-key="modalAddQuestion.objKey"
      :parameters="modalAddQuestion.parameters"
      @save="onModalConfirmSave"
    />
  </div>
</template>

<script>
import SkeletonInput from '@/components/Skeleton/Input.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import UiFilter from '@/components/UI/Filter.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiDataList from '@/components/UI/DataList.vue'
import QuestionAnswerRow from '@/components/Qa/QuestionAnswerRow.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import { debounce } from '@/utils/func.util'
import { onScroll } from '@/mixins/scroll.mixin'
import { mapActions } from 'vuex'

const EDITING_MODAL_CREATE = 'create'
const EDITING_MODAL_UPDATE = 'update'

export default {
  name: 'QuestionsPanel',
  components: {
    SkeletonInput,
    SkeletonLine,
    UiInput,
    UiButton,
    UiFilter,
    UiTitle,
    UiDataList,
    QuestionAnswerRow,
    ModalSimpleField,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      required: false,
      default: '',
    },
    currentQuestions: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: false,
      default: () => ({ page: 1, nbPages: 1 }),
    },
    ROLE_MERCHANT: {
      type: String,
      required: true,
    },
  },
  mixins: [onScroll],
  data() {
    return {
      query: '',
      isMobileOrTablet: window.innerWidth < 992,
      editingModal: EDITING_MODAL_CREATE,
      modalAddQuestion: {
        objKey: 'text',
        data: '',
        label: this.$t('gmbQaEdit.modal.addQuestion.label'),
        parameters: {
          type: 'text',
          mode: 'input',
          required: true,
          number: null,
        },
      },
    }
  },
  methods: {
    ...mapActions({
      createQuestion: 'qa/createQuestion',
      updateQuestion: 'qa/updateQuestion',
      deleteQuestion: 'qa/deleteQuestion',
    }),
    onInput: debounce(
      function (/* value */) {
        this.isUpdating = true
        // TODO: Filter displayed question by search value
        this.isUpdating = false
      },
      300
    ),
    back() {
      this.$router.go(-1)
    },
    onClickFilter(/* event */) {
      // TODO: Manage filters here
    },
    selectQuestionInDataList(data) {
      this.$emit('selectQuestionInDataList', data)
    },
    onClickCreateQuestion() {
      this.editingModal = EDITING_MODAL_CREATE
      this.modalAddQuestion.label = this.$t('gmbQaEdit.modal.addQuestion.label')
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    async onModalConfirmSave(question) {
      if (this.editingModal === EDITING_MODAL_CREATE) {
        await this.createQuestion({
          account: this.accountId,
          location: this.locationId,
          ...question.objKey,
        })
      } else if (this.editingModal === EDITING_MODAL_UPDATE) {
        await this.updateQuestion({
          account: this.accountId,
          location: this.locationId,
          ...question.objKey,
        })
      }
    },
    async onRowOptionsClickEditQuestion(row) {
      this.editingModal = EDITING_MODAL_UPDATE
      this.modalAddQuestion.data = row.text
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    async onRowOptionsClickDeleteQuestion(row) {
      await this.deleteQuestion({
        account: row.account,
        location: row.location,
      })
    },
    onClickPaginationButton(page) {
      this.$emit('onClickQuestionsPaginationButton', page)
    },
  },
}
</script>

<style lang="scss" scoped>
.questions-panel {
  /* stylelint-disable selector-pseudo-element-no-unknown */
  ::v-deep .ui-input__wrapper > input {
    background: white;
    height: 38px;
  }
  ::v-deep .skeleton-input {
    margin-bottom: auto;
    height: 38px;
  }
  /* stylelint-enable selector-pseudo-element-no-unknown */

  display: flex;
  flex-direction: column;
  border-right: 1px solid $grey-good-table-sep;
  width: 40%;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: $screen-md) {
    width: 100%;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $input-color-bg;
    height: 64px;

    &__input {
      margin: auto;
      width: 90%;
    }
  }
  &__filters {
    margin: 16px 16px 8px 16px;

    &__top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    &__bottom {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 16px 34px 0 8px;

      &__unreaded {
        max-width: 107px;
      }
      &__unreplied {
        max-width: 107px;
      }
    }
  }
  &__create {
    display: flex;
    align-items: center;
    border-top: 1px solid $grey-good-table-sep;
    border-left: 1px solid $grey-good-table-sep;
    background: $grey-very-light;
    min-height: 41px;

    &__label {
      margin-left: 24px;
      font-size: 12px;
      font-weight: 500;
    }
    &__button {
      margin-left: auto;
    }
  }
  &__list {
    border: 1px solid $grey-good-table-sep;
    border-right: 0;
  }
  &__pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: auto;
    border: 1px solid $grey-good-table-sep;
    border-right: 0;
    background: $grey-very-light;
    padding: 9px 0;
    width: 100%;
    overflow-x: auto;

    &__button {
      margin: 0 5px;
      border: 1px solid $grey-good-table-sep;
      border-radius: 16px;
      padding: 0;
      width: 22px;
      min-width: 22px;
      height: 22px;
      min-height: 22px;
    }
    .selected-page-button {
      background: #1faae6;
      color: white;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.questions-panel::-webkit-scrollbar {
  display: none;
}
</style>
