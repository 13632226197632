<template>
  <div class="confirm-publish-modal">
    <div class="confirm-publish-modal__blur" />
    <div class="confirm-publish-modal__content">
      <span class="confirm-publish-modal__content__label">{{ $t('gmbQaEdit.modal.label') }}</span>
      <div class="confirm-publish-modal__content__buttons">
        <ui-button
          class="confirm-publish-modal__content__buttons__no"
          button="primary"
          :label="$t('gmbQaEdit.modal.button.no').toUpperCase()"
          @click="onClickNo"
        />
        <ui-button
          class="confirm-publish-modal__content__buttons__yes"
          button="primary"
          :label="$t('gmbQaEdit.modal.button.yes').toUpperCase()"
          @click="onClickYes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'ConfirmPublishModal',
  components: {
    UiButton,
  },
  methods: {
    onClickNo(e) {
      this.$emit('onClickNo', e)
    },
    onClickYes(e) {
      this.$emit('onClickYes', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-publish-modal {
  position: absolute;
  width: 100%;
  height: 100%;

  &__blur {
    position: absolute;
    backdrop-filter: blur(10px);
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 30px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    background: white;
    width: 75%;
    min-width: 300px;
    height: 25%;
    min-height: 50px;
    backdrop-filter: none;

    &__label {
      margin-top: auto;
    }

    &__buttons {
      margin: auto;
      > button {
        margin: 0 8px;
        min-width: 78px;
        height: 32px;
        min-height: 32px;
        font-size: 14px;
        font-weight: 500;
      }
      &__no {
        color: #9696a4;
      }

      &__yes {
        border: 0;
        background: linear-gradient(to left, #1faae6, #4e5cec);
        color: white;
      }
    }
  }
}
</style>
